.css-qbdosj-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.checkbox-search {
  position: relative !important;
  opacity: 100 !important;
  width: 6% !important;
  margin-right: 5% !important;
}

.label-search {
  margin-bottom: 0;
}

.order-list {
  padding: 15px 0px !important;
  width: 20%;
}

.order-list-header>th {
  text-transform: capitalize !important;
  width: 20%;
}

.shop-search-panel-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ebebeb;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  color: #242424;
  display: block;
  padding: 5px;
  position: relative;
}

.shop-search-panel-title::before {
  color: #000;
  content: "\f107";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 7px;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.order-detail>div>div>div>label {
  font-size: 14px;
}

.b-10 {
  bottom: 10%;
}

.bg-color-f0f1f3 {
  background: #f0f1f3;
}
.card-order-detail{
  padding: 1.25rem 3rem;
  @media #{$xs-layout} {
    padding: 1.25rem 1.5rem;
  }
}