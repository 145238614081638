/*----- 12. image slider style  -----*/

.single-image {
  overflow: hidden;
  a {
    img {
      width: 100%;
      height: 400px;
      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}
